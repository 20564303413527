#container_main_question {
  width: 100%;
  height: 100vh;
  box-sizing: border-box;
  padding: 20px 60px;
  background: #5678f5b4;
}

.container_wrapper_question {
  width: 100%;
  height: 100%;
  // padding: 50px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.questions_content {
  position: relative;
  width: 100%;
  height: 100%;
  background: url(../images/ussd_bacground.svg);
  background-size: cover;
  background-position: center;
  border-radius: 30px;
  display: flex;
  // padding: 40px 70px;
  // display: flex;
  // align-items: center;
  // justify-content: center;
  padding: 5px 20px;
}

.questions_form {
  background-color: #ffffff93;
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding-left: 100px;
}
.questions_form {
  h2 {
    font-family: poppins;
    font-weight: 600;
    font-size: 30px;
    color: #141d55;
  }
  p {
    margin-top: 0 !important;
    font-family: poppins;
    font-weight: 500;
    font-size: 15px;
  }
  .questions_input {
    width: 50%;
    // height: 30px;
    outline: none;
    border: 1px solid #5679f5;
    letter-spacing: 35px;
    font-size: 25px;
    padding: 10px 20px;
    text-align: center;
    font-family: Raleway;
    font-weight: 600;
  }
  .number_input {
    width: 50%;
    // height: 30px;
    outline: none;
    border: 1px solid #5679f5;
    font-size: 25px;
    padding: 10px 20px;
    margin-top: 20px;
  }

  h6 {
    margin-top: 0 !important;
    font-family: poppins;
    font-weight: 500;
    font-size: 15px;
    text-decoration: none;
    margin-top: 40px !important;
    cursor: pointer;
    color: #6fbcc6;
  }
  button {
    background: #141d55;
    padding: 15px 100px;
    width: 50%;
    color: #ffffff;
    font-family: Poppins;
    border-radius: 30px;
    border: none;
    margin-top: 30px;
    cursor: pointer;
    font-size: 18px;
  }
}

.return_section {
  margin-top: 60px;
}
.return_section {
  a {
    color: #141d55;
    font-family: Poppins;
    text-decoration: none;
    font-size: 18px;
    font-weight: 600;
  }
}
.questions_image {
  // background-color: #ff0000;
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

@media screen and (max-width: 768px) {
  #container_main_question {
    width: 100%;
    height: 100vh;
    box-sizing: border-box;
    padding: 20px 10px;
    background: #5678f5b4;
  }

  .container_wrapper_question {
    width: 100%;
    height: 100%;
    // padding: 50px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .questions_content {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5px 0px;
    width: 100%;
    height: 100%;
    background: url(../images/ussd_bacground.svg);
  }
  .questions_form {
    background-color: #ffffff93;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    padding: 20px;
  }
  .questions_form {
    h2 {
      font-family: poppins;
      font-weight: 600;
      font-size: 25px;
      color: #141d55;
    }
    p {
      margin-top: 0 !important;
      font-family: poppins;
      font-weight: 500;
      font-size: 14px;
      text-align: left;
    }
    .questions_input {
      width: 50%;
      // height: 30px;
      outline: none;
      border: 1px solid #5679f5;
      letter-spacing: 35px;
      font-size: 25px;
      padding: 10px 20px;
      text-align: center;
      font-family: Raleway;
      font-weight: 600;
    }
    .number_input {
      width: 50%;
      // height: 30px;
      outline: none;
      border: 1px solid #5679f5;
      font-size: 25px;
      padding: 10px 20px;
      margin-top: 20px;
    }

    h6 {
      margin-top: 0 !important;
      font-family: poppins;
      font-weight: 500;
      font-size: 15px;
      text-decoration: none;
      margin-top: 40px !important;
      cursor: pointer;
      color: #6fbcc6;
    }
    button {
      background: #141d55;
      padding: 15px 100px;
      width: 50%;
      color: #ffffff;
      font-family: Poppins;
      border-radius: 30px;
      border: none;
      margin-top: 30px;
      cursor: pointer;
      font-size: 18px;
    }
  }

  .questions_image {
    // background-color: #ff0000;
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    display: none;
  }
}

/* loading css animation  Payout*/
#loading_animation_question {
  position: fixed;
  width: 100%;
  height: 100%;
  background: rgba(86, 121, 245, 0.7);
  z-index: 999;
  display: none;
}
.loading_container_question {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.loading_container_question p {
  color: #fff;
  font-family: Poppins;
  font-size: 22px;
}

.radio-label {
  font-family: poppins;
  font-size: 14px;
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 15px;
  border-radius: 6px;
  cursor: pointer;
  background-color: #f5faff;
  margin-top: 4px;

  // Nested styles for the input and label text
  .radio-input {
    font-family: poppins;
    font-size: 12px;
    display: flex;
    gap: 14px;
    align-items: center;
    justify-content: flex-end;

    // Style for the input element
    input[type="radio"] {
      outline: none;
    }

    // Style for the label text
    .option-rate {
      color: #4e4e4e;
      font-size: 12px;
      font-weight: 500;
    }
  }

  // Style for the label text
  .loan-period {
    color: #4e4e4e;
    font-size: 12px;
    font-weight: 600;
  }
}
